import { API_URL, appShortName } from "../../constants/ApiConstants";

const getHeader = () => {
  const tokenData = localStorage.getItem(appShortName + "token")
    ? localStorage.getItem(appShortName + "token")
    : null;

  let headers: any = {
    "Content-Type": "application/json",
  };
  if (tokenData) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + tokenData,
    };
  }
  return headers;
};

export const post = async (data: any, url: string) => {
  const headers = getHeader();
  try {
    const res = await fetch(`${API_URL}${url}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const api_delete = async (url: string) => {
  const headers = getHeader();
  try {
    const res = await fetch(`${API_URL}${url}`, {
      method: "DELETE",
      headers: headers,
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const get = async (url: string) => {
  const headers = getHeader();
  try {
    const res = await fetch(`${API_URL}${url}`, {
      method: "GET",
      headers: headers,
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};


export const put = async (data: any, url: string) => {
  const headers = getHeader();
  try {
    const res = await fetch(`${API_URL}${url}`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(data),
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};
