import { Close, Favorite } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Drawer,
  Box,
  Toolbar,
  IconButton,
  Tab,
  Typography,
  Grid,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  InputBase,
  TextField,
  Snackbar,
  Alert,
  Chip,
  Stack,
} from "@mui/material";
import {
  FC,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  IMG_URL,
  STRIPE_PAYMENT_GATEWAY_PUBLIC_KEY,
  appShortName,
} from "../../../constants/ApiConstants";
import { api_delete, get, post, put } from "../../../layout/Api/Api";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";
import { useCountryContext, useSnackbar } from "../../Public";
import DirectionsIcon from "@mui/icons-material/Directions";
// import { loadStripe } from "@stripe/stripe-js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";

interface DrawerProps {
  open: boolean;
  handleBagListRef?: MutableRefObject<
    | { getBagListWithBagTab: () => void }
    | { getWishListWithWishListTab: () => void }
    | null
  >;
  onClose: () => void;
}
interface CartCardProps {
  type:string;
  props: any;
  handleDeleteProp: () => void;
  handleQuantity: (newQuantity: any) => void;
  addToWishList?: () => void;
  isWishList?: boolean;
}

const CartCard: FC<CartCardProps> = ({
  type,
  handleDeleteProp,
  handleQuantity,
  addToWishList,
  isWishList = false,
  props,
}) => {
  const [quantity, setQuantity] = useState<number>(1);
  const { currentCountry } = useCountryContext();
  useEffect(() => {
    setQuantity(props.quantity || 1);
  }, []);

  const handleQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity);
    // Pass the new quantity to the parent component
    handleQuantity(newQuantity);
  };
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <img
            src={
              props.image
                ? IMG_URL + props.image
                : "/images/demo/new_arrival/0-LABGROWNDIAMONDSBEZEL-BabyBezelHuggieHoop-14K-Angled_041.png"
            }
            alt="productImg"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={8} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={1} sx={{ flexGrow: "1" }}>
            <Grid item xs={10} >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {props?.name}
              </Typography>
              <Box sx={{display:"flex",gap:"10px"}}>
                {
                  type === 'cart' ?
                  (<Typography variant="body2" sx={{ color: "#999" }}>
                    Size: {props?.size}
                  </Typography>)
                  :
                  null
                }
                <Typography variant="body2" sx={{ color: "#999" }}>
                  material : {props?.main_material}
                </Typography>
                <Typography variant="body2" sx={{ color: "#999" }}>
                  {
                  type === 'cart' ?
                  (<Typography variant="body2" sx={{ color: "#999" }}>
                     color : {props?.color}
                  </Typography>)
                  :
                  null
                }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" sx={{ textAlign: "end" }}>
                {currentCountry.currencyIcon}
                {props?.price}
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {!isWishList && (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  borderRadius: "4px",
                }}
              >
                <IconButton
                  aria-label="pre"
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: "#FAF7F1",
                    "&:hover": {
                      backgroundColor: "var(--merino-color)",
                    },
                    "&:focus": {
                      backgroundColor: "var(--merino-color)",
                    },
                  }}
                  onClick={() =>
                    handleQuantityChange(Math.max(quantity - 1, 1))
                  }
                  size="small"
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>

                <Typography variant="h6">{quantity}</Typography>
                <IconButton
                  aria-label="next"
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: "#FAF7F1",

                    "&:hover": {
                      backgroundColor: "var(--merino-color)",
                    },
                    "&:focus": {
                      backgroundColor: "var(--merino-color)",
                    },
                  }}
                  onClick={() =>
                    handleQuantityChange(
                      Math.min(quantity + 1, props?.stock_quantity)
                    )
                  }
                  size="small"
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
            <Typography
              variant="body1"
              component="span"
              sx={{ flexGrow: "1" }}
            ></Typography>
            {!isWishList && (
              <IconButton aria-label="add to wishList" onClick={addToWishList}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 9.95V4.5C6 4.22386 6.22386 4 6.5 4H17.5C17.7761 4 18 4.22386 18 4.5V19.9766C18 20.3928 17.5211 20.6268 17.1927 20.371L12.3073 16.5644C12.1266 16.4237 11.8734 16.4237 11.6927 16.5644L6.80731 20.371C6.47895 20.6268 6 20.3928 6 19.9766V15.475"
                    stroke="black"
                    strokeLinecap="round"
                  ></path>
                  <path
                    d="M8.84352 9.33005C8.98688 9.3305 9.12533 9.38059 9.22831 9.47099L9.22831 9.47099L9.22903 9.47164L11.7574 11.7792L11.7578 11.7796C11.9322 11.9425 12.0328 12.1575 12.038 12.3834C12.0545 12.4491 12.054 12.5175 12.0364 12.5831L12.0364 12.5831L12.0361 12.5842C11.9982 12.7131 11.9266 12.8318 11.8276 12.931L11.8277 12.9311L11.8262 12.9325L9.00514 15.5365L9.00517 15.5366L9.00357 15.5379C8.90076 15.6251 8.76441 15.673 8.62357 15.673C8.48274 15.673 8.34637 15.6251 8.24357 15.5379L8.24356 15.5379L8.24314 15.5376C8.19231 15.4935 8.15156 15.4406 8.12359 15.3819C8.0956 15.3231 8.08107 15.2598 8.08107 15.1956C8.08107 15.1315 8.0956 15.0681 8.12359 15.0094C8.15141 14.9509 8.1919 14.8983 8.24237 14.8544L10.3636 12.86H4.48966C4.34853 12.86 4.21205 12.8111 4.11049 12.7222C4.00872 12.6332 3.95 12.5108 3.94999 12.3816L3.95001 12.3805C3.95286 12.2524 4.01229 12.1318 4.11327 12.0435C4.21405 11.9553 4.34873 11.9056 4.48881 11.9032L4.48881 11.9032H4.48966H10.4187L8.46732 10.1376C8.41574 10.0951 8.37414 10.0434 8.34548 9.98546C8.31663 9.92716 8.30161 9.86398 8.30161 9.7999C8.30161 9.73581 8.31663 9.67263 8.34548 9.61433C8.37417 9.55634 8.41584 9.50458 8.46751 9.46198M8.84352 9.33005C8.77424 9.32818 8.70515 9.33883 8.64051 9.36145C8.57599 9.38403 8.51705 9.41814 8.46751 9.46198M8.84352 9.33005C8.8433 9.33005 8.84308 9.33004 8.84286 9.33004L8.84276 9.38004L8.84416 9.33006C8.84395 9.33006 8.84373 9.33005 8.84352 9.33005ZM8.46751 9.46198C8.46727 9.46219 8.46703 9.46241 8.46679 9.46262L8.5 9.5L8.46827 9.46136C8.46802 9.46157 8.46777 9.46177 8.46751 9.46198Z"
                    // fill="black"
                    stroke="black"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </IconButton>
            )}
            <IconButton aria-label="delete" onClick={() => handleDeleteProp()}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const ProductCart: React.ForwardRefRenderFunction<unknown, DrawerProps> = ({
  open,
  onClose,
  handleBagListRef,
}) => {
  const { t, i18n } = useTranslation();
  const [tab, setTab] = useState("1");
  const [cartList, setCartList] = useState<any[]>([]);
  const [wishList, setWishList] = useState<any[]>([]);
  const navigate = useNavigate();
  const { currentCountry } = useCountryContext();
  const [promocodeExpand, setPromocodeExpand] = useState<boolean>(false);
  const [promocode, setPromocode] = useState<string>();
  const [promocodeDiscount, setPromocodeDiscount] = useState<number>();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const { openSnackbar } = useSnackbar();
  const [enableBtn, setEnableBtn] = useState<boolean>(true);
  const [addressSnackbar, setAddressSnackbar] = useState<boolean>(false);
  const [isAccordionExpanded, setIsAccordionExpanded] =
    useState<boolean>(false);
  const [enableSubmitBtn, setEnableSubmitBtn] = useState<boolean>(false);

  const [address, setAddress] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    address: {
      address: "",
      country: "",
      city: "",
      postalCode: "",
      state: "",
    },
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    address: {
      address: "",
      country: "",
      city: "",
      postalCode: "",
      state: "",
    },
  });

  const handleSubmitAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddress((prevState) => {
      // Separate address fields
      if (name.startsWith("address.")) {
        return {
          ...prevState,
          address: {
            ...prevState.address,
            [name.split(".")[1]]: value,
          },
        };
      }
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const storeData = localStorage.getItem("divineuser");
  const userID = storeData ? JSON.parse(storeData) : "null";
  const id = userID.id;

  useEffect(() => {
    const allFieldsFilled =
      address.firstName &&
      address.lastName &&
      address.mobileNumber.length === 10 && // Ensure mobile number is 10 digits
      address.address.address &&
      address.address.country &&
      address.address.city &&
      address.address.postalCode &&
      address.address.state;

    setEnableSubmitBtn(!allFieldsFilled); // Enable button if all fields are filled
  }, [address]);

  const handleSubmitAddressBtn = () => {
    const newErrors = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      address: {
        address: "",
        country: "",
        city: "",
        postalCode: "",
        state: "",
      },
    };

    put(address, `users/${id}/update`)
      .then((res) => {
        if (res && res.status === "OK") {
          setEnableBtn(false);
          setAddressSnackbar(true);
          setIsAccordionExpanded(false);

          console.log("api integrate successfully🚀");
        } else {
          console.log("not successfully ");
        }
      })
      .catch((err) => console.log("error", err));

    //

    setErrors(newErrors);

    // If valid, submit the form data
    console.log("Form submitted:", address);
  };

  const getUserDetails = () => {
    const divineuserString = localStorage.getItem("divineuser");
    if (!divineuserString) {
      console.log("No user found in localStorage.");
      return;
    }
    const divineuser = JSON.parse(divineuserString);
    const userId = divineuser.id;

    get(`users/${userId}/details`)
      .then((res) => {
        if (res && res.status === "OK") {
          setEnableBtn(false);
          const userData = res.data;
          setAddress({
            firstName: userData.firstName || "",
            lastName: userData.lastName || "",
            mobileNumber: userData.mobileNumber || "",
            address: {
              address: userData.address.address || "",
              country: userData.address.country || "",
              city: userData.address.city || "",
              postalCode: userData.address.postalCode || "",
              state: userData.address.state || "",
            },
          });
        } else {
          console.log(
            "Something went wrong:",
            res.message || "No message available"
          );
        }
      })
      .catch((err) => console.log("Error:", err));
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const formFieldStyles = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "gray", // Border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "pink", // Border color when focused
      },
    },
  };

  // others
  useEffect(() => {
    handleCartList();
    getWishList();
    getUserDetails();
  }, [
    localStorage.getItem(appShortName + "cart"),
    localStorage.getItem(appShortName + "token") ||
      localStorage.getItem(appShortName + "user"),
  ]);
  

  const getBagListWithBagTab = () => {
    setTab("1");
    handleCartList();
  };

  const getWishListWithWishListTab = () => {
    setTab("2");
    getWishList();
  };

  useImperativeHandle(handleBagListRef, () => ({
    getBagListWithBagTab,
    getWishListWithWishListTab,
  }));

  useEffect(() => {
    if (localStorage.getItem(appShortName + "user")) {
      const localStorageBagData = JSON.parse(
        localStorage.getItem(appShortName + "cart") || "[]"
      );
      if (localStorageBagData.length !== 0) {
        const postRequests = localStorageBagData.map((item: any) => {
          return post(item, "bags/add");
        });

        Promise.all(postRequests)
          .then(() => {
            handleCartList();
          })
          .catch((error) => {
            console.error("Error adding products to bag:", error);
          });
      }
    }
  }, [localStorage.getItem(appShortName + "user")]);

  //for wishlist
  useEffect(() => {
    const user = localStorage.getItem(appShortName + "user");
    if (user) {
      const localStorageWishlistData = JSON.parse(
        localStorage.getItem(appShortName + "wishlist") || "[]"
      );
      if (localStorageWishlistData.length !== 0) {
        const postRequests = localStorageWishlistData.map((item: any) => {
          return post(item, `wishlist/add/${item.id}`);
        });

        Promise.all(postRequests)
          .then(() => {
            getWishList();
            // Clear local storage wishlist after syncing
            localStorage.removeItem(appShortName + "wishlist");
          })
          .catch((error) => {
            console.error("Error adding products to wishlist:", error);
          });
      }
    }
  }, [localStorage.getItem(appShortName + "user")]);

  const handleCartList = () => {
    setCartList([]);
    if (localStorage.getItem(appShortName + "token")) {
      get(
        `bags/get/country/${currentCountry.code}/language/${i18n.language}`
      ).then((response: any) => {
        if (response && response.status === "OK") {
          setCartList(response.data);
          let tempEstimatedTotal = 0;
          response.data.map((item: any) => {
            tempEstimatedTotal += item.price * item.quantity;
          });
          setTotalPrice(tempEstimatedTotal);
        }
      });
    } else {
      const data = JSON.parse(
        localStorage.getItem(appShortName + "cart") || "[]"
      );
      post(
        data,
        `bags/public/country/${currentCountry.code}/language/${i18n.language}`
      ).then((response: any) => {
        if (response && response.status === "OK") {
          setCartList(response.data);
          let tempEstimatedTotal = 0;
          response.data.map((item: any) => {
            tempEstimatedTotal += item.price * item.quantity;
          });
          setTotalPrice(tempEstimatedTotal);
        }
      });
    }
  };

  const getWishList = () => {
    if (localStorage.getItem(appShortName + "token")) {
      get(
        `wishlist/get/country/${currentCountry.code}/language/${i18n.language}`
      )
        .then((response: any) => {
          if (response && response.status === "OK") {
            setWishList(response.data);
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error fetching wishlist:", error);
        });
    } else {
      const localData = JSON.parse(
        localStorage.getItem(appShortName + "wishlist") || "[]"
      );
      post(
        localData,
        `bags/public/country/${currentCountry.code}/language/${i18n.language}`
      )
        .then((response: any) => {
          if (response && response.status === "OK") {
            setWishList(response.data);
          } else {
            // Handle unexpected server responses
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error syncing wishlist:", error);
        });
    }
  };

  const handleCartItemDelete = (productId: any) => {
    if (localStorage.getItem(appShortName + "user")) {
      api_delete(`bags/delete/${productId}`).then((response: any) => {
        if (response && response.status === "OK") {
          handleCartList();
        }
      });
    } else {
      let tempCartList = JSON.parse(
        localStorage.getItem(appShortName + "cart") || "[]"
      );
      tempCartList = tempCartList.filter((item: any) => item.id !== productId);
      localStorage.setItem(appShortName + "cart", JSON.stringify(tempCartList));
      handleCartList();
    }
  };

  const handleWishListItemDelete = (productId: any) => {
    if (localStorage.getItem(appShortName + "user")) {
      // User is logged in, send request to delete item from wishlist
      api_delete(`wishlist/remove/${productId}`)
        .then((response: any) => {
          if (response && response.status === "OK") {
            getWishList();
          } else {
            console.error("Failed to delete item from wishlist:", response);
          }
        })
        .catch((error: any) => {
          console.error("Error deleting item from wishlist:", error);
        });
    } else {
      // User is not logged in, remove item from local storage wishlist
      let tempWishList = JSON.parse(
        localStorage.getItem(appShortName + "wishlist") || "[]"
      );
      tempWishList = tempWishList.filter((item: any) => item.id !== productId);
      localStorage.setItem(
        appShortName + "wishlist",
        JSON.stringify(tempWishList)
      );
      getWishList();
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const stripeCheckout = () => {
    if (localStorage.getItem(appShortName + "user")) {
      const userId = JSON.parse(
        localStorage.getItem(appShortName + "user") || "[]"
      ).id;
      get(`users/${userId}`).then((response: any) => {
        if (response && response.status === "OK") {
          const data = {
            countryCode: currentCountry?.code,
            deliveryAddress: response.data.address,
            languageCode: i18n.language,
            products: cartList,
            // promoCode: promocode !== null || promocode !== undefined ? promocode | null,
            promoCode: promocode,
          };
          localStorage.setItem(
            `${appShortName}orderDetails`,
            JSON.stringify(data)
          );

          let paymentproductsData: any[] = [];

          cartList.map((item) => {
            console.log(item);
            paymentproductsData.push({
              id: item.id,
              quantity: item.quantity,
              size: item.size,
            });
          });

          const paymentData = {
            countryCode: currentCountry.code,
            languageCode: i18n.language,
            products: paymentproductsData,
            promoCode: promocode,
            deliveryAddress: {
              address: address.address.address,
              city: address.address.city,
              country: address.address.country,
              postalCode: address.address.postalCode,
              state: address.address.state,
            },
            mobileNumber: address.mobileNumber,
          };
          post(paymentData, "stripe/pay").then((response: any) => {
            if (response && response.status === "OK") {
              window.open(response.data);
            }
          });
        }
      });
    } else {
      onClose();
      navigate("/signin");
    }
  };

  //paypal
  const PaypalCheckout = () => {
    if (localStorage.getItem(appShortName + "user")) {
      const userId = JSON.parse(
        localStorage.getItem(appShortName + "user") || "[]"
      ).id;
      get(`users/${userId}`).then((response: any) => {
        if (response && response.status === "OK") {
          const data = {
            countryCode: currentCountry?.code,
            deliveryAddress: response.data.address,
            languageCode: i18n.language,
            products: cartList,
            // promoCode: promocode !== null || promocode !== undefined ? promocode | null,
            promoCode: promocode,
          };
          localStorage.setItem(
            `${appShortName}orderDetails`,
            JSON.stringify(data)
          );

          let paymentproductsData: any[] = [];
          cartList.map((item) =>
            paymentproductsData.push({
              id: item.id,
              quantity: item.quantity,
            })
          );

          const paymentData = {
            countryCode: currentCountry.code,
            languageCode: i18n.language,
            products: paymentproductsData,
            promoCode: promocode,
          };
          post(paymentData, "paypal/pay").then((response: any) => {
            if (response && response.status === "OK") {
              window.open(response.data);
            }
          });
        }
      });
    } else {
      onClose();
      navigate("/signin");
    }
  };

  const applyPromoCode = () => {
    if (localStorage.getItem(appShortName + "user")) {
      post(null, `promoCodes/isValid?promoCode=${promocode}`).then(
        (response: any) => {
          if (response && response.status === "OK") {
            setPromocodeDiscount(response.data.discount);
          } else {
            openSnackbar(response.message, "error");
          }
        }
      );
    } else {
      openSnackbar(
        "Log in to unlock exclusive discounts with your promo code. Don't miss out on savings – sign in now!",
        "error"
      );
    }
  };

  /**
   * Handle Product Quantity change
   *
   * @param itemId
   * @param newQuantity
   */
  const handleQuantityChange = (itemId: number, newQuantity: number) => {
    // Update the quantity in the bagList state
    const updatedBagList = cartList.map((item: any) => {
      if (item.id === itemId) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    let tempTotalPrice = 0;
    updatedBagList.map((item: any) => {
      tempTotalPrice += item.price * item.quantity;
    });
    setTotalPrice(tempTotalPrice);
    setCartList(updatedBagList);
  };

  const addToWishList = (productId: any) => {
    post(null, `wishlist/add/${productId}`).then((response: any) => {
      if (response && response.status === "OK") {
        getWishList();
      }
    });
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className="cartDrawer"
      // hideBackdrop
      // ModalProps={{
      //   // container: document.querySelector("#drawer-container"), // Specify the container where the drawer will be rendered
      //   BackdropProps: {
      //     // invisible: true, // Make the backdrop invisible
      //     sx:{
      //       top: 105
      //     }
      //   },
      // }}
      PaperProps={{
        elevation: 2, // Remove the shadow
        style: {
          width: "430px",
          backgroundColor: "var(--light-color)",
          // padding: "32px 32px 16px",
          boxSizing: "border-box",
        },
        sx: {
          "@media (max-width: 500px)": {
            width: "100% !important", // Example change for smaller screens
          },
          // '@media (max-width: 550px)': {
          //   width: '80% !important', // Example change for smaller screens
          // },
        },
      }}
      SlideProps={{
        appear: false,
      }}
    >
      {/* Drawer content goes here */}
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "flex-end",
            height: "45px !important",
            minHeight: "45px !important",
          }}
        >
          <IconButton aria-label="" onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Toolbar>

        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="basic tabs example"
              variant="fullWidth"
            >
              <Tab
                label="Your Bags"
                value="1"
                sx={{ width: "100%", color: "var(--dark-color)" }}
              />
              <Tab
                label="Wishlist"
                value="2"
                icon={<Favorite sx={{ color: "#FF0000 !important" }} />}
                iconPosition="end"
                sx={{ width: "100%", color: "var(--dark-color)" }}
              />
            </TabList>
          </Box>

          <TabPanel
            value="1"
            sx={{ padding: "24px 12px 12px", boxSizing: "border-box" }}
          >
            {cartList.length > 0 ? (
              <Box>
                <Box>
                  {cartList.map((item: any, index: number) => (
                    <Box key={item.id + "" + index}>
                      <CartCard
                      type="cart"
                        props={item}
                        handleDeleteProp={() => handleCartItemDelete(item.id)}
                        handleQuantity={(newQuantity: any) =>
                          handleQuantityChange(item.id, newQuantity)
                        }
                        addToWishList={() => addToWishList(item.id)}
                      />
                      <Divider
                        sx={{ margin: "8px 16px 16px", borderColor: "#ccc" }}
                      />
                    </Box>
                  ))}
                </Box>
                {(promocodeDiscount === null ||
                  promocodeDiscount === undefined) && (
                  <Box mb={2}>
                    <Accordion
                      expanded={promocodeExpand}
                      onChange={(event: any, isExpanded: any) => {
                        setPromocodeExpand(isExpanded);
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          promocodeExpand ? <RemoveIcon /> : <AddIcon />
                        }
                        aria-controls="shortBy-content"
                        id="shortBy-header"
                        sx={{ textTransform: "capitalize" }}
                      >
                        <Typography>Add Promocode</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Paper
                          component="form"
                          sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Promo Code"
                            inputProps={{ "aria-label": "Promo Code" }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setPromocode(event.target.value)}
                          />
                          <Divider
                            sx={{ height: 28, m: 0.5 }}
                            orientation="vertical"
                          />
                          <IconButton
                            color="primary"
                            sx={{ p: "10px" }}
                            aria-label="directions"
                            onClick={applyPromoCode}
                            disabled={!promocode}
                          >
                            <DirectionsIcon />
                          </IconButton>
                        </Paper>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
                <Paper sx={{ padding: "12px" }}>
                  {promocodeDiscount !== null &&
                    promocodeDiscount !== undefined && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 1,
                          }}
                        >
                          <Typography variant="body1">Subtotal:</Typography>
                          <Typography variant="body1" fontWeight="bold">
                            {currentCountry.currencyIcon}
                            {totalPrice}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 1,
                            color: "var(--softPink-color)",
                          }}
                        >
                          <Typography variant="body1">
                            Promo Code Discount:
                          </Typography>
                          <Typography variant="body1" fontWeight="bold">
                            -{currentCountry.currencyIcon}
                            {(totalPrice * promocodeDiscount) / 100}
                          </Typography>{" "}
                        </Box>
                      </>
                    )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Typography variant="body1">Estimated Shipping:</Typography>
                    <Typography variant="body1" fontWeight="bold">
                      Free
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Typography variant="body1">Total Payable:</Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {currentCountry.currencyIcon}
                      {
                      promocodeDiscount !== null &&
                      promocodeDiscount !== undefined
                        ? (totalPrice - (totalPrice * promocodeDiscount) / 100).toFixed(2)
                        : totalPrice.toFixed(2)
                      }
                    </Typography>
                  </Box>

                  {/* accordion */}
                  <Box sx={{ margin: "10px 0px" }}>
                    <Accordion
                      expanded={isAccordionExpanded}
                      sx={{ background: "var(--LightGrayishBrown-color)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        onClick={() => setIsAccordionExpanded((prev) => !prev)}
                      >
                        <Typography sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:"10px"}}>
                          Billing Address : 
                          {
                            address.firstName && address.address.country ?(
                              <Stack direction="row" spacing={1}>
                                <Chip label={address.firstName} sx={{background:"var(--merino-color)",color:"var(--softPink-color)"}}/>
                                <Chip label={address.address.country} sx={{background:"var(--merino-color)",color:"var(--softPink-color)"}} />
                              </Stack>
                            ):(
                              null
                            )
                          }
                        </Typography>
                      </AccordionSummary>
                      <Divider variant="middle" />
                      <AccordionDetails>
                        <form>
                          <TextField
                            placeholder="First Name"
                            name="firstName"
                            value={address.firstName}
                            onChange={handleSubmitAddress}
                            required
                            fullWidth
                            margin="normal"
                            error={!address.firstName.trim()}
                            helperText={!address.firstName.trim() && "Name is required"}
                          />
                          <TextField
                            placeholder="Last Name"
                            name="lastName"
                            value={address.lastName}
                            onChange={handleSubmitAddress}
                            required
                            fullWidth
                            margin="normal"
                            error={!address.lastName.trim()}
                            helperText={!address.lastName.trim() && "LastName is required"}
                          />
                          <TextField
                            placeholder="Mobile Number"
                            name="mobileNumber"
                            value={address.mobileNumber}
                            onChange={handleSubmitAddress}
                            required
                            fullWidth
                            margin="normal"
                            type="tel"
                            inputProps={{ maxLength: 10 }}
                            error={!address.mobileNumber.trim()}
                            helperText={!address.mobileNumber.trim() && "Mobile number is required"}
                          />
                          <TextField
                            placeholder="Enter Your Address"
                            name="address.address"
                            value={address.address.address}
                            onChange={handleSubmitAddress}
                            required
                            fullWidth
                            margin="normal"
                            error={!address.address.address.trim()}
                            helperText={!address.address.address.trim() && "Address is required"}
                          />
                          <TextField
                            placeholder="Country"
                            name="address.country"
                            value={address.address.country}
                            onChange={handleSubmitAddress}
                            required
                            fullWidth
                            margin="normal"
                            error={!address.address.country.trim()}
                            helperText={!address.address.country.trim() && "Country is required"}
                          />
                          <TextField
                            placeholder="City"
                            name="address.city"
                            value={address.address.city}
                            onChange={handleSubmitAddress}
                            required
                            fullWidth
                            margin="normal"
                            error={!address.address.city.trim()}
                            helperText={!address.address.city.trim() &&  "City is required"}
                          />
                          <TextField
                            placeholder="PIN"
                            name="address.postalCode"
                            value={address.address.postalCode}
                            onChange={handleSubmitAddress}
                            required
                            fullWidth
                            margin="normal"
                            type="number"
                            error={!address.address.postalCode.trim()}
                            helperText={!address.address.postalCode.trim() && "PostalCode is required"}
                          />
                          <TextField
                            placeholder="State"
                            name="address.state"
                            value={address.address.state}
                            onChange={handleSubmitAddress}
                            required
                            fullWidth
                            margin="normal"
                            error={!address.address.state.trim()}
                            helperText={!address.address.state.trim() && "State is required"}
                          />

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{
                                height: "45px",
                                width: "200px",
                                marginBottom: "10px",
                                backgroundColor: "var(--merino-color)",
                                color: "var(--softPink-color)",
                                "&:hover": {
                                  backgroundColor: "var(--merino-color)",
                                  color: "var(--softPink-color)",
                                },
                                "&:focus": {
                                  backgroundColor: "var(--merino-color)",
                                  color: "var(--softPink-color)",
                                },
                              }}
                              onClick={handleSubmitAddressBtn}
                              disabled={enableSubmitBtn}
                            >
                              Submit
                            </Button>
                          </Box>
                        </form>
                      </AccordionDetails>
                    </Accordion>
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      height: "45px",
                      marginBottom: "10px",
                      backgroundColor: "var(--merino-color)",
                      color: "var(--softPink-color)",
                      "&:hover": {
                        backgroundColor: "var(--merino-color)",
                        color: "var(--softPink-color)",
                      },
                      "&:focus": {
                        backgroundColor: "var(--merino-color)",
                        color: "var(--softPink-color)",
                      },
                    }}
                    disabled={enableBtn || enableSubmitBtn}
                    onClick={stripeCheckout}
                  >
                    checkout - with - stripe
                  </Button>

                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      height: "45px",
                      backgroundColor: "var(--merino-color)",
                      color: "var(--softPink-color)",
                      "&:hover": {
                        backgroundColor: "var(--merino-color)",
                        color: "var(--softPink-color)",
                      },
                      "&:focus": {
                        backgroundColor: "var(--merino-color)",
                        color: "var(--softPink-color)",
                      },
                    }}
                    disabled={enableBtn || enableSubmitBtn}
                    onClick={PaypalCheckout}
                  >
                    checkout - with - paypal
                  </Button>
                  <Snackbar
                    open={addressSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setAddressSnackbar(false)}
                    message="Shipping Address saved successfully 🥳"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    action={
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setAddressSnackbar(false)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                  />
                </Paper>
              </Box>
            ) : (
              <Box>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Your bag is empty—explore our 
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                curated favourites and start filling it up!
                </Typography>
                <Box my={4}>
                  {/* <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <Button
                        variant="outlined"
                        fullWidth
                        className="cart-button"
                      >
                        BEST SELLERS
                      </Button>
                    </Grid>
                    <Grid item sm={6}>
                      <Button
                        variant="outlined"
                        fullWidth
                        className="cart-button"
                      >
                        SHOP ALL
                      </Button>
                    </Grid>
                    <Grid item sm={6}>
                      <Button
                        variant="outlined"
                        fullWidth
                        className="cart-button"
                      >
                        GIFTS
                      </Button>
                    </Grid>
                    <Grid item sm={6}>
                      <Button
                        variant="outlined"
                        fullWidth
                        className="cart-button"
                      >
                        NEW ARRIVALS
                      </Button>
                    </Grid>
                  </Grid> */}
                </Box>
              </Box>
            )}
          </TabPanel>
          <TabPanel value="2">
            <Box>
              {wishList.length > 0 ? (
                <Box>
                  {wishList.map((item: any, index: number) => (
                    <Box key={item.id + "" + index}>
                      <CartCard
                        type = "wishlist"
                        props={item}
                        handleDeleteProp={() =>
                          handleWishListItemDelete(item.id)
                        }
                        handleQuantity={(newQuantity: any) =>
                          handleQuantityChange(item.id, newQuantity)
                        }
                        isWishList={true}
                      />
                      <Divider
                        sx={{ margin: "8px 16px 16px", borderColor: "#ccc" }}
                      />
                    </Box>
                  ))}
                </Box>
              ) : (
                <>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }} mb={2}>
                    Your save for later list is empty.
                  </Typography>
                  <Typography variant="body1">
                    Save products from your bag that you want to purchase later
                    to the list.
                  </Typography>
                </>
              )}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Drawer>
  );
};

export default ProductCart;
