import {
  Box,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  InputAdornment,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import PublicIcon from "@mui/icons-material/Public";
import { FC } from "react";
import { headerFooterProps, useCountryContext } from "../public/Public";

library.add(fab);

const Footer: FC<headerFooterProps> = ({
  countries,
  languages,
  onLanguageChange,
}) => {
  const { t, i18n } = useTranslation();
  const {currentCountry, handleCurrentCountry } = useCountryContext();
  const navigate = useNavigate();
  return (
    <Toolbar className="footer_section" sx={{ backgroundColor: "#ece9e0" }}>
      <Box className="footer_inner_section">
        <Box className="language_country_section">
          {/* {languages.length > 0 && (
            <TextField
              id="outlined-select-language"
              select
              variant="standard"
              value={i18n.language}
              defaultValue={languages?.[0]?.code}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ color: "var(--dark-color)", fontSize: "18px" }}
                  >
                    <GTranslateIcon sx={{ fontSize: "18px" }} />
                  </InputAdornment>
                ),
                style: {
                  color: "var(--dark-color)",
                  fontFamily: "var(--font-family2)",
                  fontSize: "14px",
                  marginRight: "16px",
                },
              }}
            >
              {languages.map((option) => (
                <MenuItem
                  key={option.code}
                  value={option.code}
                  onClick={() => onLanguageChange(option.code)}
                  sx={{
                    fontFamily: "var(--font-family2)",
                    fontSize: "14px",
                    textTransform: "capitalize",
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {countries.length > 0 && (
            <TextField
              id="outlined-select-countries"
              select
              defaultValue={countries?.[0]?.code}
              value={currentCountry.code}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ color: "var(--dark-color)", fontSize: "18px" }}
                  >
                    <PublicIcon sx={{ fontSize: "18px" }} />
                  </InputAdornment>
                ),
                style: {
                  color: "var(--dark-color)",
                  fontFamily: "var(--font-family2)",
                  fontSize: "14px",
                },
              }}
            >
              {countries.map((option) => (
                <MenuItem
                  key={option.code}
                  value={option.code}
                  onClick={() => handleCurrentCountry(option)}
                  sx={{ fontFamily: "var(--font-family2)", fontSize: "14px" }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )} */}
        </Box>
        <Box className="membership">
          
          <Typography
            variant="body2"
            component="a"
            className="link"
            onClick={() => navigate("/terms-and-conditions")}
          >
            {t("footer.T&C")}
          </Typography>
          <Typography
            variant="body2"
            component="a"
            className="link"
            onClick={() => navigate("privacy-policy")}
          >
            {t("footer.Privacy-policy")}
          </Typography>
        </Box>
        <Box className="copyright">
          <Typography variant="body2">{t("footer.copyright")}</Typography>
        </Box>
        <Box className="social_icon">
          <IconButton aria-label="" size="small">
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </IconButton>
          <IconButton aria-label="" size="small">
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </IconButton>
          <IconButton aria-label="" size="small">
            <FontAwesomeIcon icon={["fab", "pinterest"]} />
          </IconButton>
          <IconButton aria-label="" size="small">
            <FontAwesomeIcon icon={["fab", "x-twitter"]} />
          </IconButton>
        </Box>
      </Box>
    </Toolbar>
  );
};

export default Footer;
