import React, { useEffect } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import styled from '@emotion/styled';
import img from '../../assets/runcookie.jpg'; // Ensure the path to the image is correct

interface WebCookiesProps {
  open: boolean;
  onClose: () => void;
}

// const CustomDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialog-paper': {
//     height: 'auto',
//     width: '400px',
//     display: 'flex',
//     flexDirection: 'column',
//     borderRadius: '15px',
//     padding: '10px',
//     gap: '10px',
//     background: "#f2cbd1",
//     position: 'fixed',
//     bottom: '10px',
//     right: '20px',
//     margin: 0,
//   },
// }));

// const RightContent = styled(Box)({
//   padding: '10px',
//   borderRadius: '15px',
//   backgroundColor: '#f2cbd1',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
// });

// const CookieImage = styled(Box)({
//   height: '70px',
//   width: '70px',
//   borderRadius: '50%',
//   marginBottom: '10px',
// });

// const RightHeading = styled(Typography)({
//   textAlign: 'center',
//   width: '80%',
//   borderBottom: '1px solid #8b8a8a',
//   fontSize: '2.5rem',
//   marginBottom: '10px',
// });

// const RightText = styled(Typography)({
//   padding: '5px',
//   fontSize: '1.5rem',
//   textAlign: 'center',
//   lineHeight: '25px',
//   marginBottom: '20px',
// });

// const ButtonGroup = styled(Box)({
//   display: 'flex',
//   flexDirection: 'row',
//   gap: '30px',
// });

// const StyledButton = styled(Button)({
//   height: '40px',
//   width: '150px',
//   borderRadius: '20px',
//   fontSize: '1rem',
//   cursor: 'pointer',
//   backgroundColor: '#da7391',
//   color: '#ffd8d8',
//   fontWeight: 600,
//   boxShadow: '-10px 7px 47px #67353e',
//   letterSpacing: '2px',
//   '&:hover': {
//     opacity: 0.8,
//   },
// });


const CustomDialog = styled(Dialog)`
  & .MuiDialog-paper {
    display: flex;
    border-radius: 15px;
    // padding: 10px;
    gap: 10px;
    background: #f2cbd1;
    position: fixed;
    bottom: 10px;
    right: 20px;
    margin: 0;
    width: 100%;
    max-width: 400px;

    @media (max-width: 600px) {
      width: 100%; // Increase width for smaller screens
      right: 5px;
      bottom: 5px;
      display:flex;
      justify-content:center;
    }

    @media (max-width: 400px) {
      width: 100%; // Further adjust width for extra small screens
      padding: 5px;
      display:flex;
      justify-content:center;
    }
  }
`;

const RightContent = styled(Box)`
  padding: 10px;
  border-radius: 15px;
  background-color: #f2cbd1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CookieImage = styled(Box)`
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    height: 50px;
    width: 50px;
  }
`;

const RightHeading = styled(Typography)`
  text-align: center;
  width: 80%;
  border-bottom: 1px solid #8b8a8a;
  font-size: 2.5rem;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    font-size: 1.8rem;
  }

  @media (max-width: 400px) {
    font-size: 1.5rem;
  }
`;

const RightText = styled(Typography)`
  padding: 5px;
  font-size: 1.5rem;
  text-align: center;
  line-height: 25px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 1.2rem;
    line-height: 20px;
  }

  @media (max-width: 400px) {
    font-size: 1rem;
    line-height: 18px;
  }
`;

const ButtonGroup = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (max-width: 600px) {
    // flex-direction: column;
    gap: 10px;
  }
`;

const StyledButton = styled(Button)`
  height: 40px;
  width: 150px;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #da7391;
  color: #ffd8d8;
  font-weight: 600;
  box-shadow: -10px 7px 47px #67353e;
  letter-spacing: 2px;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;


const WebCookies: React.FC<WebCookiesProps> = ({ open, onClose }) => {
  useEffect(() => {
    // Check if the cookie consent has been given
    const consent = document.cookie.split('; ').find(row => row.startsWith('cookieConsent='))?.split('=')[1];
    if (consent) {
      onClose();
    }
  }, [onClose]);

  const handleAccept = () => {
    document.cookie = "cookieConsent=true; path=/; max-age=31536000"; // 1 year
    onClose();
  };

  const handleDecline = () => {
    onClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        onClose();
      }}
      fullWidth
      maxWidth="sm"
      sx={{zIndex:"500",}}
    >
      <RightContent>
        <CookieImage>
          <img src={img} alt="cookie" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
        </CookieImage>
        <RightHeading>Cookies</RightHeading>
        <RightText>
          We use cookies to personalize our website and offerings to your interests and for measurement and analytics purposes. By using our website and our products, you agree to our use of cookies.
        </RightText>
        <ButtonGroup>
          <StyledButton variant="contained" color="error" onClick={handleDecline}>
            Decline
          </StyledButton>
          <StyledButton variant="contained" color="error" onClick={handleAccept}>
            Accept
          </StyledButton>
        </ButtonGroup>
      </RightContent>
    </CustomDialog>
  );
};

export default WebCookies;
