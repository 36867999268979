import {
  Box,
  Button,
  Container,
  Typography,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  TextField,
  Select,
  Divider,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { post } from "../layout/Api/Api";

const data = [
  { name: "John Doe", createdAt: new Date(), last: new Date(), status: "Open" },
  { name: "Jane Smith", createdAt: new Date(), last: new Date(), status: "Closed" },
  { name: "Alice Johnson", createdAt: new Date(), last: new Date(), status: "Open" },
  { name: "Bob Brown", createdAt: new Date(), last: new Date(), status: "Closed" },
  { name: "Charlie White", createdAt: new Date(), last: new Date(), status: "Open" },
  { name: "Alice Johnson", createdAt: new Date(), last: new Date(), status: "Open" },
  { name: "Bob Brown", createdAt: new Date(), last: new Date(), status: "Closed" },
  { name: "Charlie White", createdAt: new Date(), last: new Date(), status: "Open" },
];

const data1 = [
  { name: "John Doe", createdAt: new Date(), last: new Date(), status: "Open" },
  { name: "Jane Smith", createdAt: new Date(), last: new Date(), status: "Closed" },
  { name: "Alice Johnson", createdAt: new Date(), last: new Date(), status: "Open" },
];

const items = [
  {
    id: 1,
    image: "/img/bracelet/homebracelet.png",
    title: "Diamond necklace, Black onyx necklace",
    user: "Sharon",
    date: "Completed 21 Aug",
    price: "$58.39",
  },
  {
    id: 2,
    image: "/img/bracelet/homebracelet.png",
    title: "Sterling silver, diamond, diamond ring",
    user: "Mary Booth",
    date: "Completed 21 Aug",
    price: "$58.37",
  },
  {
    id: 3,
    image: "/img/bracelet/homebracelet.png",
    title: "Diamond ring, Wedding ring set, Opal",
    user: "Kelly Newman",
    date: "Completed 21 Aug",
    price: "$79.25",
  },
  
 
];

const AllTicketsAndStatus = () => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  // const [selectedValue, setSelectedValue] = useState(items[0]?.title || "");
  // const [feedback, setFeedback] = useState("");
  const location = useLocation();
  const [dialogData,setDialogData] = useState({
    subject: items[0]?.title || '',
    message:"",
  });
 

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleSelectChange = (event: any) => {
    setDialogData((prevData) => ({
      ...prevData,
      subject: event.target.value,
    }));
  };

  const handleFeedbackChange = (event:any) => {
    setDialogData((prevData:any) => ({
      ...prevData,
      message: event.target.value,
    }));
  };

 
  const handleSubmit = () => {
    console.log(dialogData); // Log form data to the console
    post(dialogData,`ticket/add`).then((res)=>{
      if(res && res.status){
        console.log("api integrate successfully 🚀")
      }else{
        console.log("issue calling api 🤡")
      }
    }).catch((err)=>console.log("error",err))
    setOpen(false);
  };

  useEffect(()=>{
    if(location.state && location.state.openTicketDialog){
      setOpen(true);
    }
  },[location?.state?.openTicketDialog])

  

  return (
    <Box sx={{ background: "var(--bigCords3-bg)" }}>
      <Container fixed sx={{ height: "100vh", p: 2 }}>
        <Box sx={{ border: "2px solid #dfdddd", borderRadius: "5px" }}>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              sx={{
                ".MuiTabs-indicator": { background:"var(--softPink-color)" },
              }}
            >
              {["Open Query", "Close Query"].map((label, index) => (
                <Tab
                  key={index}
                  label={label}
                  sx={{
                    color: "black",
                    "&.Mui-selected": { color: "black" },
                    "&:hover": { color: "pink" },
                  }}
                />
              ))}
            </Tabs>
            <Button
              variant="contained"
              sx={{
                background: "var(--LightGrayishBrown-color)",
                color: "var(--softPink-color)",
                borderRadius: "3px",
                width: "150px",
                fontWeight: 600,
                m: 1,
                transition: "background 0.3s ease-in, opacity 0.3s ease-in",
                '&:hover':{
                  background: 'var(--LightGrayishBrown-color)',
                  opacity:0.8,
                }
              }}
              onClick={handleDialogOpen}
            >
              Create Query
            </Button>
            <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperProps={{ sx: { height: '400px' } }}
    >
      <DialogTitle textAlign={'center'}>{'Create a New Query'}</DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Select
          value={dialogData.subject}
          onChange={handleSelectChange}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>Select an item</em>;
            }
            return selected;
          }}
          sx={{ width: '400px', mt: 1 }}
        >
          {items.map((item) => (
            <MenuItem
              key={item.id}
              value={item.title}
              sx={{
                bgcolor: 'grey.100',
                mb: 1,
                borderRadius: 1,
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={item.image}
                  sx={{ width: 40, height: 40, borderRadius: '5px' }}
                  variant='square'
                />
              </ListItemAvatar>
              <ListItemText
                primary={item.title}
                secondary={
                  <Typography variant='body2' sx={{ color: 'grey.600' }}>
                    {`${item.user} • ${item.date} • ${item.price}`}
                  </Typography>
                }
                sx={{ ml: 2 }}
              />
            </MenuItem>
          ))}
        </Select>

        <TextField
          hiddenLabel
          fullWidth
          multiline
          rows={4}
          name='description'
          placeholder='Enter your query'
          variant='outlined'
          type='text'
          sx={{ paddingTop: '10px', mt: 1, width: '400px' }}
          value={dialogData.message}
          onChange={handleFeedbackChange}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={handleDialogClose}
          sx={{
            background: 'var(--LightGrayishBrown-color)',
            color: 'var(--softPink-color)',
            borderRadius: '3px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{
            background: 'var(--LightGrayishBrown-color)',
            color: 'var(--softPink-color)',
            borderRadius: '3px',
          }}
        >
          Submit
        </Button>
      </DialogActions>
           </Dialog>
          </Box>

          <Box sx={{ mt: 1, borderTop: "2px solid #dfdddd", borderRadius: "5px" }}>
            {data.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  m: 2,
                }}
              >
                <img height={300} src="/img/ticket4.webp" alt="ticket" />
                <Typography variant="body1" sx={{ fontSize: "1.3rem" }}>
                  You Don't have any open Query
                </Typography>
              </Box>
            ) : (
              <Box sx={{ width: "100%", overflowY: "auto" }}>
                {value === 0 && (
                  <Box sx={{}}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Last</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Link to="/settings">{row.name}</Link>
                              </TableCell>
                              <TableCell>{row.createdAt.toLocaleString()}</TableCell>
                              <TableCell>{row.last.toLocaleString()}</TableCell>
                              <TableCell>{row.status}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}

                {value === 1 && (
                  <Box sx={{ }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Last</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data1.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Link to="/settings">{row.name}</Link>
                              </TableCell>
                              <TableCell>{row.createdAt.toLocaleString()}</TableCell>
                              <TableCell>{row.last.toLocaleString()}</TableCell>
                              <TableCell>{row.status}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            )}
          </Box>

        </Box>
      </Container>
    </Box>
  );
};

export default AllTicketsAndStatus;
