import React, { useState, useRef, useMemo } from "react";
import {
  Box,
  Container,
  Grid,
  Rating,
  Typography,
  TextField,
  Button,
  Avatar,
} from "@mui/material";
import JoditEditor from "jodit-react";
import { Height } from "@mui/icons-material";

const initialMessages = [
  { id: 1, text: "Hello, I need help with my order.", sender: "client" },
  {
    id: 2,
    text: "Sure, I can help you with that. Can you provide your order number?",
    sender: "admin",
  },
  { id: 3, text: "My order number is #12345.", sender: "client" },
  {
    id: 4,
    text: "Thank you! Let me check your order details.",
    sender: "admin",
  },

  { id: 5, text: "my order is .", sender: "client" },
  {
    id: 6,
    text: "Sure, I can help you with that.can you send the screenshorts of order.",
    sender: "admin",
  },
  { id: 7, text: "ok i will send.", sender: "client" },
  {
    id: 8,
    text: "Thank you! Let me check your order details. And your query",
    sender: "admin",
  },
];

const SupportChat = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [formData, setFormData] = useState<{
    content: string;
    file: File | null;
  }>({ content: "", file: null });
  const [messages, setMessages] = useState(initialMessages);
  const [messageInput, setMessageInput] = useState("");

  //joditEditor
  const placeholder = "Enter your query";
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder,
      height: "400px",
    }),
    [placeholder]
  );
  //

  //file upload
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setFormData((prev) => ({ ...prev, file }));
  };

  const handleEditorChange = (newContent: string) => {
    setContent(newContent);
    setFormData((prev) => ({ ...prev, content: newContent }));
  };

  const handleResponseClick = () => {
    console.log("Editor Content:", formData.content);
    console.log("Selected File:", formData.file);
  };

  //
  const handleSendMessage = () => {
    if (messageInput.trim() !== "") {
      console.log("User Message:", messageInput);

      // Add new message to the chat
      setMessages([
        ...messages,
        { id: messages.length + 1, text: messageInput, sender: "client" },
      ]);

      // Clear the input field
      setMessageInput("");
    }
  };

  return (
    <Container maxWidth="lg" sx={{ background: "var(--merino-color)", mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={8} p={1}>
          <Typography
            component="p"
            sx={{
              color: "#a7a7a7",
              fontSize: "1.5rem",
              fontWeight: 600,
              padding: "10px 0",
            }}
          >
            [ticket : #83475] Regarding to the product Query
          </Typography>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            onBlur={handleEditorChange}
            onChange={() => {}}
          />
          <TextField
            type="file"
            onChange={handleFileChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              background: "#f5f5f5",
              border: "1px dotted",
              mt: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
              },
            }}
            fullWidth
          />
          <Button
            variant="contained"
            sx={{
              background: "var(--LightGrayishBrown-color)",
              color: "var(--softPink-color)",
              borderRadius: "3px",
              width: "150px",
              fontWeight: 600,
              mt: 1,
              mb: 1,
              "&:hover": {
                background: "var(--LightGrayishBrown-color)",
                opacity: 0.7,
              },
            }}
            onClick={handleResponseClick}
          >
            Response
          </Button>

          <Box sx={{ m: 1 }}>
            <Typography variant="body1" sx={{ color: "#a7a7a7" }}>
              {" "}
              Query Resolution Chat
            </Typography>
          </Box>

          <Box
            sx={{
              background: "var(--LightGrayishBrown-color)",
              height: "400px",
              borderRadius: "5px",
              p: 3,
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // Hide scrollbar but allow scrolling
              // Hide scrollbar for Firefox
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                height: "100%",
                "&::-webkit-scrollbar": {
                  width: "0px", // Corrected to hide the scrollbar
                  height: "0px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "transparent", // Make scrollbar thumb transparent
                },
                msOverflowStyle: "none", // Hide scrollbar for IE and Edge
                scrollbarWidth: "none",
              }}
            >
              {messages.map((message) => (
                <Grid
                  container
                  key={message.id}
                  justifyContent={
                    message.sender === "admin" ? "flex-end" : "flex-start"
                  }
                  sx={{ mb: 2 }}
                >
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {message.sender === "client" && (
                        <Avatar sx={{ mr: 1, bgcolor: "primary.main" }}>
                          U
                        </Avatar>
                      )}
                      <Box
                        sx={{
                          p: 2,
                          borderRadius: "10px",
                          backgroundColor:
                            message.sender === "admin" ? "#e0f7fa" : "#f8bbd0",
                          maxWidth: "60%",
                        }}
                      >
                        <Typography>{message.text}</Typography>
                      </Box>
                      {message.sender === "admin" && (
                        <Avatar sx={{ ml: 1, bgcolor: "secondary.main" }}>
                          A
                        </Avatar>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Box>

            {/* Message input and send button */}
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <TextField
                fullWidth
                //   variant="outlined"
                placeholder="Type a message..."
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                sx={{
                  mr: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "gray",
                    },
                  },
                }}
              />

              <Button
                variant="contained"
                sx={{
                  background: "var(--LightGrayishBrown-color)",
                  color: "var(--softPink-color)",
                  borderRadius: "3px",
                  width: "150px",
                  height: "50px",
                  fontWeight: 600,
                  "&:hover": {
                    background: "var(--LightGrayishBrown-color)",
                    opacity: 0.8,
                  },
                }}
                onClick={handleSendMessage}
              >
                Send
              </Button>
            </Box>
          </Box>
          
        </Grid>

        <Grid item xs={4} p={1} mt={5}>
          <Typography
            component={"p"}
            sx={{
              fontSize: "1rem",
              fontWeight: 600,
              padding: "10px 0",
              color: "#a7a7a7",
            }}
          >
            Status : Open
          </Typography>
          <Box
            sx={{
              width: "100%",
              background: "var(--LightGrayishBrown-color)",
              borderRadius: "10px",
              p: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box
                  component="img"
                  src="/img/bracelet/homebracelet3.png"
                  alt="Product"
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    borderBottom: "1px solid black",
                    width: "50%",
                  }}
                >
                  Product Name
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  $99.99
                </Typography>
                <Rating
                  name="product-rating"
                  sx={{ "& .MuiRating-icon": { fontSize: "20px" } }}
                  value={4}
                  readOnly
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

      </Grid>
    </Container>
  );
};

export default SupportChat;
