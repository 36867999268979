import { Outlet, useLocation } from "react-router-dom";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { Alert, Box, Snackbar } from "@mui/material";
import {
  createContext,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { get } from "../layout/Api/Api";
import Splash from "../layout/splashScreen/Splash";
import { CartProvider } from "./context/CartContext";
import WebCookies from "./cookies/WebCookies";
import Cookies from "js-cookie";

export interface headerFooterProps {
  countries: any[];
  languages: any[];
  onLanguageChange: (lng: string) => void;
}
interface currentCountryType {
  code: string;
  currencyABN: string;
  currencyIcon: string;
  icon: string;
  name: string;
}

interface SplashContextType {
  setSplashScreen: (value: boolean) => void;
}

type SnackbarContextType = {
  openSnackbar: (message: string, severity: string) => void;
};
type CountryContextType = {
  currentCountry: currentCountryType;
  handleCurrentCountry: (cont: currentCountryType) => void;
};
const SnackbarContext = createContext<SnackbarContextType>({
  openSnackbar: () => {},
});

const splashContext = createContext<SplashContextType>({
  setSplashScreen: () => {},
});

const countryContext = createContext<CountryContextType>({
  currentCountry: {
    code: "",
    currencyABN: "",
    currencyIcon: "",
    icon: "",
    name: "",
  },
  handleCurrentCountry: () => {},
});

export const useSnackbar = () => useContext(SnackbarContext);
export const useCountryContext = () => useContext(countryContext);
export const useSplashScreen = () => useContext(splashContext);

function Public() {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [languages, setLanguages] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [isSplash, setIsSplash] = useState(false);
  const [currentCountry, setCurrentCountry] = useState<currentCountryType>({
    code: "us",
    currencyABN: "us",
    currencyIcon: "$",
    icon: "USA",
    name: "United States",
  });
  const [snackbarState, setSnackbarState] = useState({
    message: "",
    severity: "severity",
  });
  const isMounted = useRef(false);
  const [webCookiesState, setWebCookiesState] = useState(true);
  const location = useLocation();

  const openSnackbar = (message: string, severity: string) => {
    setSnackbarState({
      message: message,
      severity: severity,
    });
    setOpen(true);
  };

  const handleCurrentCountry = (cont: currentCountryType) => {
    setCurrentCountry(cont);
  };

  useEffect(() => {
    if (!isMounted.current) {
      get("languages/getAll").then((response: any) => {
        if (response && response.status === "OK") {
          setLanguages(response.data);
        }
      });
      get("countries/getAll").then((response: any) => {
        if (response && response.status === "OK") {
          setCountries(response.data);
          const cont = {
            code: response.data?.[0].code,
            currencyABN: response.data?.[0].currencyABN,
            currencyIcon: response.data?.[0].currencyIcon,
            icon: response.data?.[0].icon,
            name: response.data?.[0].name,
          };
          setCurrentCountry(cont);
        }
      });
      getSlugsByLanguageCode("en");
      isMounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);

  // useEffect(() => {
  //   console.log("currentCountry", currentCountry);
  // }, [currentCountry]);

  const closeSnackbar = () => {
    setOpen(false);
  };

  const changeLanguage = (lng: string) => {
    if (!i18n.hasResourceBundle(lng, "translation")) {
      // If resources are not present, fetch them
      getSlugsByLanguageCode(lng);
    } else {
      // If resources are present, simply change the language
      i18n.changeLanguage(lng);
      console.log("i18n1", i18n.language, lng);
    }
  };

  const updateResources = (language: any, newResources: any) => {
    i18n.addResourceBundle(language, "translation", newResources, true, true);
  };

  const getSlugsByLanguageCode = (languageCode: string) => {
    get(`slugs/language/${languageCode}`).then((response: any) => {
      if (response && response.status === "OK") {
        updateResources(languageCode, response.data);
        i18n.changeLanguage(languageCode);
        // console.log("i18n", i18n.language, languageCode);
      }
    });
  };

  const setSplashScreen = (value: boolean) => {
    setIsSplash(value);
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      <SnackbarContext.Provider value={{ openSnackbar }}>
        <splashContext.Provider value={{ setSplashScreen }}>
          <countryContext.Provider
            value={{ currentCountry, handleCurrentCountry }}
          >
            <CartProvider>
              <Header
                countries={countries}
                languages={languages}
                onLanguageChange={changeLanguage}
              />
              <Box sx={{ minHeight: "80vh" }}>
                <Outlet />
              </Box>
              <Footer
                countries={countries}
                languages={languages}
                onLanguageChange={changeLanguage}
              />
            </CartProvider>
          </countryContext.Provider>
        </splashContext.Provider>
      </SnackbarContext.Provider>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ width: "100%", top: "0 !important" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbarState.severity as any}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>

    
      {isSplash && <Splash open={isSplash} />}


     {webCookiesState && <WebCookies
        open={webCookiesState}
        onClose={() => {
          setWebCookiesState(false);
        }}
      />}  


    </Box>
  );
}

export default Public;
