// export const API_URL = 'http://192.168.1.5:8080/api/v1/';
// export const API_URL = "http://146.190.153.79:8080/divine/api/v1/";
export const API_URL = "https://divineempyrean.com/api/v1/";//live
export const IMG_URL = API_URL + "images?url=";
export const BASE_URL = "https://divineempyrean.com";


export const appShortName = "divine";
export const STRIPE_PAYMENT_GATEWAY_SECRET_KEY =
  "sk_test_51OuRQBSAVbRdSiFuSHGGpLM2lobYEiq0Vo8dRRRo1BgD2AbM06Caqb4TGz12QKSNhPx2th1FmyUCZjph4F4h2vvU00My8ZU1aM";
export const STRIPE_PAYMENT_GATEWAY_PUBLIC_KEY =
  "pk_test_51OyROySALK1bDY3SDxfTlXwbirEEkVQtW5kAVEpQLXc1hkwMnEYIhS9K3ReZel76oPcUA60CbZWnet9XF2UWsvqV001QLUgPEn";
export const domainUrl = "128.199.28.240";
export const captchaSiteKey = '6LcSkEIiAAAAAFG_iGFzQD8MOa2S9sYmdAQ-JoRR';
// export const PAYPAL_CLIENT_ID ="AfzV9rQ8PP7ckKRNkINa37KDLMz7vlTmlQB4mm-HiSkh_FOhPC3YjOcj923kTUX-vrOafV5kf_uPu70r";
// export const PAYPAL_SECRET_KEY = "EEN4rortQ-VmAEKT7xc0vTM6rFIrqL2e22wrP0tseF-rBDobi7NrySbcRbMVeNFYE1xsj1bpKnQBozYf";



export const PAY_PAL_CLIENT_ID = "AaqizpaZLORuIA9KfPijA9dTAPYC1a-pFxSxaVdmrWfrmQsPiuAbl7cHX-cb16J4BQe4dEU8DmkDT28B";
export const PAY_PAL_SECRET_KEY = "EKi3TK9Vnm33RT9j-RK2Ou5rX5kVp5UMKgBW5LcvFbOs7zx_1DBPbKzsDJc3oFGnr7kE_IH458--IoUe";
